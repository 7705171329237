import React from 'react';

import {
  Image,
} from 'grommet';
import { useTranslation } from 'react-i18next';
import i18nParent from '../../i18n';
import english from '../../assets/images/english.png';
import lao from '../../assets/images/lao.png';
import khmer from '../../assets/images/khmer.png';
import indon from '../../assets/images/indon.png';

import ChangeLanguage from './ChangeLanguage';

const Language = () => {
  const { t, i18n } = useTranslation('translations', { i18nParent });

  return (
    <div style={{ margin: '0px auto', width: '300px' }}>
      <div style={{ float: 'left', textAlign: 'center', width: '75px' }}>
        <Image
          alt={t('English')}
          onClick={() => { ChangeLanguage(i18n, 'en'); }}
          src={english}
          style={{ cursor: 'pointer', display: 'inline !important' }}
          title={t('English')}
        />
      </div>
      <div style={{ float: 'left', textAlign: 'center', width: '75px' }}>
        <Image
          alt={t('Lao')}
          onClick={() => { ChangeLanguage(i18n, 'lo'); }}
          src={lao}
          style={{ cursor: 'pointer', display: 'inline' }}
          title={t('Lao')}
        />
      </div>
      <div style={{ float: 'left', textAlign: 'center', width: '75px' }}>
        <Image
          alt={t('Khmer')}
          onClick={() => { ChangeLanguage(i18n, 'km'); }}
          src={khmer}
          style={{ cursor: 'pointer', display: 'inline' }}
          title={t('Khmer')}
        />
      </div>
      <div style={{ float: 'left', textAlign: 'center', width: '75px' }}>
        <Image
          alt={t('Bahasa')}
          onClick={() => { ChangeLanguage(i18n, 'id'); }}
          src={indon}
          style={{ cursor: 'pointer', display: 'inline' }}
          title={t('Bahasa Indonesia')}
        />
      </div>
    </div>
  );
};

export default Language;
