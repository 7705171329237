import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import XHR from 'i18next-xhr-backend';

import translationEn from './locales/en/translation.json';
import translationKm from './locales/km/translation.json';
import translationLo from './locales/lo/translation.json';

i18n
.use(XHR)
.use(LanguageDetector)
.init({
  debug: true,
  defaultNS: 'translations',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
    formatSeparator: ','
  },
  keySeparator: false,
  ns: ['translations'],
  react: {
    useSuspense: false,
    wait: true
  },
  resources: {
    en: translationEn,
    lo: translationLo,
    km: translationKm,
  },
});

export default i18n;
