import React, { useRef } from 'react';
import { QrReader } from 'react-qr-reader';
import jsQR from 'jsqr';
import {
  Button,
  Form,
  FormField,
  Text,
  TextInput,
} from 'grommet';
import API from '@aws-amplify/api';
import { Auth } from 'aws-amplify';
import MaterialIcon from 'material-icons-react';
import { useTranslation } from 'react-i18next';
import i18nParent from '../../i18n';
import TagContext from '../../global/TagContext';
import TagInfo from './TagInfo';
import Location from './Location';
import TagHistory from './TagHistory';
import Forms from '../Forms';
import Spinner from '../Common/Spinner';

async function claimTagOwnershipApi(language, tagId) {
  const apiName = 'lirs';
  const path = '/claim-tag-ownership';

  const myInit = {
    headers: { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` },
    body: {
      language: language.substr(0, 2),
      tagId,
      username: `${(await Auth.currentSession()).getIdToken().payload['cognito:username']}`,
    },
  };

  return API.post(apiName, path, myInit);
}

async function tagSearchApi(search, language) {
  const apiName = 'lirs';
  const path = '/tag-search';

  const myInit = {
    headers: { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` },
    body: {
      language: language.substr(0, 2),
      search,
      username: `${(await Auth.currentSession()).getIdToken().payload['cognito:username']}`,
    },
  };

  return API.post(apiName, path, myInit);
}

const handleError = (err) => {
  console.error(err);
};



const Tag = () => {
  const { t, i18n } = useTranslation('translations', { i18nParent });
  const { language } = i18n;

  const { tagState, tagDispatch } = React.useContext(TagContext);
  const [isDead, setIsDead] = React.useState(false);
  const [deadDate, setDeadDate] = React.useState(false);
  const [deadReason, setDeadReason] = React.useState(false);
  const [deadLocation, setDeadLocation] = React.useState(false);
  const [markedDeadBy, setMarkedDeadBy] = React.useState(false);
  const [isTagHasOwner, setIsTagHasOwner] = React.useState(false);
  const [isTagOwner, setIsTagOwner] = React.useState(false);
  const [isClaming, setIsClamming] = React.useState(false);
  const [isShowScanner, setIsShowScanner] = React.useState(false);

  const {
    found,
    isadmin,
    search,
    tagid,
    taglocationid,
  } = tagState;

  let tagLookup = '';
  if (window.location.pathname !== '/') {
    tagLookup = window.location.pathname.replace('/', '');
  }

  const tagSearchFormSubmitButton = useRef(null);
  if (search === '' && tagLookup !== '') {
    setTimeout(() => {
        tagSearchFormSubmitButton.current.click();
      }, 500);
  }

  const handleResult = (result, error) => {
    if (!!result) {
      const data = result.text
      const regex = /([A-Z])\w+/;
      const match = data.match(regex);

      if (match) {
        const substring = match[0];
        setSearchValue(substring);
      } else {
        console.error('QR code not found tag');
      }
      setIsShowScanner(false);
      payload = {
        eid: null,
        found: false,
        history: null,
        isadmin: null,
        isTagHasOwner: null,
        isTagOwner: null,
        locations: null,
        properties: null,
        search: search2,
        tagid: null,
        vid: null,
      };
      tagDispatch({
        type: 'tagSearch',
        payload,
      });
    }
    if (!!error) {
    }
  }
  const handleImageUpload = (event) => {
    setSearchValue('');
    setIsShowScanner(false);
    try {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
      const imageUrl = event.target.result;
      const canvas = document.createElement('canvas');
  
      const img = new Image();
      img.onload = () => {
        canvas.width = img.width;
        canvas.height = img.height;
        canvas.getContext('2d').drawImage(img, 0, 0);
  
        const imageData = canvas.getContext('2d').getImageData(0, 0, canvas.width, canvas.height);
        const code = jsQR(imageData.data, imageData.width, imageData.height);
  
        if (code) {
          const data = code.data
          const regex = /([A-Z])\w+/;
          const match = data.match(regex);

          if (match) {
            const substring = match[0];
            setSearchValue(substring);
          } else {
            console.error('QR code not found tag');
          }
        } else {
          console.error('QR code not found');
        }
      };
  
      img.src = imageUrl;
      };
      reader.readAsDataURL(file);
    } catch (error) {
      setSearchValue('');
    }
  };

  // form inputs
  const [searchValue, setSearchValue] = React.useState(tagLookup !== '' ? tagLookup : search);

  const mess = 'Loading';

  let payload = null;
  // if user switches language whilst viewing tag information then we want to hide all tag information
  //  e.g. make not found so that they have to search again
  // otherwise tag context will continue to be other language
  // and lookup lists in forms
  const [tagLanguage, setTagLanguage] = React.useState(language);

  if (tagLanguage !== language) {
    setTagLanguage(language);

    if (found) {
      // N.B. We need to reset here - order of execution with form (when taglocationid is specified)

      // reset if switching tags
      payload = {
        eid: null,
        found: null,
        history: null,
        locations: null,
        properties: null,
        search,
        tagid: null,
        vid: null,
        isTagHasOwner: null,
        isTagOwner: null
      };
      // update tag context
      tagDispatch({
        type: 'tagSearchChangeLanguage',
        payload,
      });

      // tag search
      tagSearchApi(search, language).then((response) => {
         // found
         payload = {
          eid: response.eid,
          found: true,
          history: response.history,
          locations: response.locations,
          properties: response.properties,
          search,
          tagid: response.tagid,
          vid: response.vid,
          isTagHasOwner:response.istaghasowner,
          isTagOwner:response.istagowner,
          isClaming: false
                  // fundo
        };

        // update tag context
        tagDispatch({
         type: 'tagSearchChangeLanguage',
         payload,
        });
      }).catch(error => {
        alert(t('The tag not found'));
        return false;
      });

      // <Text alignSelf="center" textAlign="center">{`${t('Loading')} ...`}</Text>
      return (
        <Spinner spinnermessage={mess} />
      );
    }
  }

  return (
    
    <div style={{ margin: '0px auto', textAlign: 'center', width: '90%' }}>
      <Form
        onSubmit={
          ({ value }) => {
            // cannot change value directly
            // need different name than tag context property
            const search2 = value.search.toUpperCase();

            if (search2 === '') {
              // eslint-disable-next-line no-alert
              alert(t('Error: Please enter a tag EID or VID.'));
              return false;
            }

            // reset if switching tags
            payload = {
              eid: null,
              found: null,
              history: null,
              isadmin: null,
              isTagHasOwner: null,
              isTagOwner: null,
              locations: null,
              properties: null,
              search: search2,
              tagid: null,
              vid: null,
            };

            // update tag context
            tagDispatch({
              type: 'tagSearch',
              payload,
            });

            // tag search
            return tagSearchApi(search2, language).then(
              (response) => {
                if (response.found) {
                  setIsDead(response.dead);
                  setDeadLocation(response.deadlocation);
                  if(response.deadtime){
                    const deadtime = response.deadtime;
                    const deadDate = deadtime.split(" ")[0];
                    setDeadDate(deadDate);
                  }
                  setDeadReason(response.deadreason);
                  setMarkedDeadBy(response.markeddeadby);
                  setIsTagHasOwner(response.istaghasowner);
                  setIsTagOwner(response.istagowner)
                  setIsClamming(false)
                  // found
                  payload = {
                    eid: response.eid,
                    found: true,
                    history: response.history,
                    isadmin: response.isadmin,
                    isTagHasOwner: response.istaghasowner,
                    isTagOwner: response.istagowner,
                    locations: response.locations,
                    properties: response.properties,
                    search: search2,
                    tagid: response.tagid,
                    vid: response.vid,
                    isClaming: isClaming
                    
                  };
                } else {
                  // not found
                  payload = {
                    eid: null,
                    found: false,
                    history: null,
                    isadmin: null,
                    isTagHasOwner: null,
                    isTagOwner: null,
                    locations: null,
                    properties: null,
                    search: search2,
                    tagid: null,
                    vid: null,
                  };
                }

                // update tag context
                tagDispatch({
                  type: 'tagSearch',
                  payload,
                });
              }
            ).catch(error=>{
              alert(t('No tag found, please check your tag details'));
              return false;
              });
          }
        }
        >
        <Text>
          {t('Search tag EID or VID')}
        </Text>
        <br />
        <div
          style={{ margin: '15px auto', maxWidth: '200px', position: 'relative'}}>
          {
          <div style={{ display: 'flex', alignItems: 'center' }}>
          <Button
            icon={<MaterialIcon icon="qr_code_scanner" color="black" />}
            onClick={() => {
              setIsShowScanner(true)
            }}
          />
          <input type="file" accept="image/*" onChange={handleImageUpload} />
        </div>
          }
        {isShowScanner &&
        <div
          style={{ margin: '15px auto', maxWidth: '200px', position: 'relative', backgroundColor:'white'}}>
          <QrReader
            constraints={ {facingMode: 'environment'} }
            delay={500}
            onError={handleError}
            ref={tagSearchFormSubmitButton}
            // chooseDeviceId={()=>selected}
            style={{ margin: '0px auto', maxWidth: '200px'}}
            onResult={handleResult}
          />
        </div>}
      </div>

        <FormField
          component={TextInput}
          name="search"
          onChange={(event) => setSearchValue(event.target.value)}
          style={{ margin: '0px auto', maxWidth: '200px' }}
          value={searchValue}
        />

        <br />

        <Button
          className="button"
          icon={<MaterialIcon icon="search" color="white" />}
          label={t('Search')}
          primary
          ref={tagSearchFormSubmitButton}
          type="submit"
          value="submit"
        />

      </Form>

      {
        (
          () => {
            const TagOwnershipButton = () => {
              // Alllow claim owner reagrdless of tag ownership
              return (
                <div>
                  <hr />
                  <Button
                    className="alert"
                    onClick={() => {
                      // record tag location
                      claimTagOwnershipApi(
                        language,
                        tagid
                      ).then((response) => {
                        setIsClamming(true)
                        payload = {
                          isClaming: true,
                        };
                        // update tag context
                        tagDispatch({
                          type: 'claimTagOwnership',
                          payload,
                        });
                      }).catch(error=>{
                        alert(t(error.response.data.errorMessage));
                      });
                    }}
                    icon={<MaterialIcon icon="verified_user" color="white" />}
                    label={t('Claim tag ownership')}
                    style={{ marginTop: '1em', marginBottom: '1em', minWidth: '200px' }}
                  />

                </div>
              );
            };

            const DataEntry = () => {
              if (isDead) {
                let messageDeadInfo = ''; // Initialize as an empty string

                if (deadDate && deadLocation && markedDeadBy && deadReason) {
                  // All parameters have values, construct the full message
                  messageDeadInfo = `Tag marked as dead on ${deadDate} at ${deadLocation} by ${markedDeadBy} with reason ${deadReason}`;
                } else {
                  // Some parameters are null, construct a partial message based on available information
                  const availableInfo = [];
                  if (deadDate) availableInfo.push(`dead on ${deadDate}`);
                  if (deadLocation) availableInfo.push(`at ${deadLocation}`);
                  if (markedDeadBy) availableInfo.push(`by ${markedDeadBy}`);
                  if (deadReason) availableInfo.push(`with reason ${deadReason}`);

                  messageDeadInfo = `Tag marked as dead, ${availableInfo.join(', ')}`;
                }
                // Display a message instead of rendering components
                //const messageDeadInfo =`Tag marked as dead on ${deaddate} at ${deadlocation} by ${markeddeadby} with reason ${deadreason}`
                return <Text>{messageDeadInfo}</Text>;
              }
              if (taglocationid == null) {
                return(
                  <div style={{ margin: '0px auto', textAlign: 'center' }}>
                    <Location />
                  </div>
                );
              }

              if (isadmin || isTagOwner) {
                return (<Forms />);
              }
              };

            // tag not found
            if (!found) {
              return (
                <div>
                  <br />
                  <Text textAlign="center">
                    {t('No tag found')}
                  </Text>
                </div>
              );
            }

            if (found) {
              return (
                <div>
                    <hr />

                    <div style={{ margin: '0px auto', textAlign: 'center' }}>
                      <TagInfo />
                    </div>
                    {DataEntry()}
                    {((!isTagHasOwner || !isTagOwner) && !isClaming) && <TagOwnershipButton/>}
                    {isClaming && 
                      <div>
                          <br />
                          <Text textAlign="center">
                            {t('Claim request sent')}
                          </Text>
                    </div>}
                    
                    {(isadmin || isTagOwner) && 
                      <div style={{ margin: '0px auto', textAlign: 'center' }}>
                        <TagHistory />
                      </div>}
                  </div>
              );
            }

            // no search done yet to determine if found or not found
            return ('');
          }
        )()
      }

    </div>
  );
};

export default Tag;

